<template>
    <div>
      <v-card>
        <v-tabs v-model="tab" fixed-tabs background-color="#2596be" dark>
          <v-tabs-slider color="#b4dbdb"></v-tabs-slider>
            <v-tab :disabled="enableTeam">EQUIPE</v-tab>
            <v-tab :disabled="enableIntegrant">INTEGRANTES</v-tab>
          
            <v-tab-item>
            <v-card flat>
              <v-card-text>
                <!-- alterei o ref -->
                <v-form @submit.prevent="createMedicalTeam" ref="form">
                  <v-container>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="4" v-if="(this.$store.state.profile_id == 1)">
                        <v-select 
                        :items="unities"
                        :rules="formRules"
                        color="#b4dbdb" 
                        v-model="teamForm.unity_id"
                        item-value="id"
                        item-text="hospital"  
                        dense 
                        outlined 
                        label="Unidade">
                        </v-select>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="8">
                        <v-text-field 
                        color="#b4dbdb" 
                        :rules="formRules" 
                        label="Nome da Equipe"
                        outlined
                        v-model="teamForm.name" 
                        dense></v-text-field>
                      </v-col>
                    </v-row>
                    <div class="buttons">
                      <v-row>
                        <v-btn class="rounded-lg" type="submit" elevation="0" color="blue">
                          <span class="textBtn"> Salvar </span>
                        </v-btn>
                        <div class="spacediferent"></div>
                        <v-btn class="rounded-lg" elevation="0" @click="$router.push('/equipe-medica')" color="grey">
                          <span class="textBtn"> Voltar</span>
                        </v-btn>
                      </v-row>
                    </div>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
            </v-tab-item>
            
            <v-tab-item>
            <v-card flat>
              <v-card-text>
                <!-- alterei o ref -->
                <v-form @submit.prevent="addTeam" ref="form">
                  <v-container>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="4">
                       <v-select 
                        :items="users"
                        :rules="formRules"
                        color="#b4dbdb" 
                        v-model="medicalTeamForm.user_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Integrante">
                        </v-select>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="4">
                       <v-select 
                        :items="specialties"
                        :rules="formRules"
                        color="#b4dbdb" 
                        v-model="medicalTeamForm.specialty_id"
                        item-value="id"
                        item-text="name"  
                        dense 
                        outlined 
                        label="Especialidade">
                        </v-select>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="4">
                        <v-tooltip right color="orange">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mx-1"  small fab dark color="orange" v-bind="attrs" v-on="on" type="submit" @click="addTeam()">
                              <v-icon dark>
                                mdi-plus
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Inserir Integrante</span>
                      </v-tooltip>
                        <p class="space"></p>
                        <!-- <v-btn class="rounded-lg" elevation="0" color="#91ba5b" @click="$router.push('/perfis-de-usuarios')">
                          <span class="textBtn"> Adicionar Integrante</span>
                        </v-btn> -->
                      </v-col>
                    </v-row>

                    <template>
                  <v-data-table
                    :headers="headers"
                    :items="medicalTeamTable"
                    :items-per-page="10"
                    class="elevation-1"
                  >
                    <template v-slot:item.actions="{ item }">
                        <v-icon 
                          size="20"
                          class="icon"
                          color="red" 
                          align-item="center"
                          @click="deleteTeam(item)">
                           mdi-trash-can-outline
                        </v-icon>
                      </template>
                  </v-data-table>
                </template>

                  <p class="space-personalized"></p>
                    <div class="buttons">
                      <v-row>
                        <v-btn class="rounded-lg"  :disabled="enableOut" elevation="0" color="blue" @click="$router.push('/equipe-medica')">
                          <span class="textBtn"> Cadastrar Equipe Médica </span>
                        </v-btn>
                      </v-row>
                    </div>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
            </v-tab-item>
        </v-tabs>
      </v-card>
  
      <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
            Fechar
          </v-btn>
        </template>
    </v-snackbar>
    </div>
  </template>  
  <script>
  
  import axios from "@/axios";
  // import { isArgumentsObject } from "util/types";
  
  export default {
    data() {
      return {
        headers: [
            {
              text: "Integrante",
              value: "users.name",
              align: "center",
            },
            {
              text: "Especialidade",
              value: "specialties.name",
              align: "center",
            },
            {
              text: "Ação",
              value: "actions",
              align: "center",
            }
        ],
        totalUsers: 0,
        numberOfPages: 0,
        passengers: [],
        loading: true,
        options: {},
        sortBy: "name",
        disabled: true,
        receiverId: null,
        enableIntegrant: true,
        enableTeam: false,
        enableOut: true,
        message: "",
        team_id: null,
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
        team: [],
        tab: null,
        users: [],
        unities: [],
        teamForm: {
          enabled: ""
        },
        medicalTeamForm:{
          team_id: "",
          
        },
        specialties:[],
        medicalTeamTable:[],
        formRules: [
          (v) => !!v || "Este campo é obrigatório",
        ],
      };
    },
    
    created() {
      axios.get("/specialties").then((response) => {this.specialties = response.data.data});
      axios.get("/users/listUsers").then((response) => {this.users = response.data.data});
      axios.get("/unities").then((response) => {this.unities = response.data.data});
    },
  
    watch: {
      sortBy: {
        handler() {
  
        }
      },
  
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    methods: {
  
      //Carrega as coisas ao acessar a tela
      indoDataTable() {
        this.loading = true;
      },

      mounted() {
        this.indoDataTable();
      },

      createMedicalTeam(){
        if (this.$refs.form.validate() && this.$store.state.profile_id == 1){
          this.teamForm.enabled = "1"
          const team = this.teamForm
          axios
          .post(`/teams/create`, team)
          .then((response) => {
            this.team_id = response.data
            this.enableIntegrant = false
            this.enableTeam = true
            this.indoDataTable();
            this.tab = 1
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
        }else if(this.$refs.form.validate()){
          this.teamForm.enabled = "1"
          this.teamForm.unity_id = this.$store.state.unity_id
          const team = this.teamForm
          axios
          .post(`/teams/create`, team)
          .then((response) => {
            this.team_id = response.data
            this.enableIntegrant = false
            this.enableTeam = true
            this.indoDataTable();
            this.tab = 1
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
        }
      },
  
      addTeam(){
        if(this.$refs.form.validate() && this.$store.state.profile_id == 1){
          this.medicalTeamForm.team_id = this.team_id.id
          const medicalTeam = this.medicalTeamForm
          axios
          .post(`/medical_team`, medicalTeam).then((response) => {
            this.snackbar = true;
            this.message = response.data.message
            this.medicalTeamForm.user_id = ""
            this.medicalTeamForm.specialty_id = ""
            this.enableOut= false
          }).catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          }).finally(() => {
            axios.get("/medical_team").then((response) => {
               this.medicalTeamTable = response.data.data;
               this.medicalTeamTable = this.medicalTeamTable.filter((x) => x.team_id == this.medicalTeamForm.team_id);
              });
          });
        }else{
          this.medicalTeamForm.team_id = this.team_id.id
          const medicalTeam = this.medicalTeamForm
          axios
          .post(`/medical_team`, medicalTeam).then((response) => {
            this.snackbar = true;
            this.message = response.data.message
            this.medicalTeamForm.user_id = ""
            this.medicalTeamForm.specialty_id = ""
            this.enableOut= false
          }).catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          }).finally(() => {
            axios.get("/medical_team").then((response) => {
               this.medicalTeamTable = response.data.data;
               this.medicalTeamTable = this.medicalTeamTable.filter((x) => x.team_id == this.medicalTeamForm.team_id);
              });
          });
        }
      },

      deleteTeam(item){
        const teamId = item.id
        axios.delete(`/medical_team/${teamId}`).then((response) => {
            this.loading = true;
            this.message = response.data.message;
            this.snackbar = true;
          });
      }
  }
};
  </script>
  
  <style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }
  
  .space {
    width: 5px;
  }
  
  .spacediferent {
    width: 7px;
  }
  
  .space-personalized {
    padding: 15px;
  }
  
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right,
        #27ae60,
        #1abc9c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,
        #ee106d,
        #e46987);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  
  .icon {}
  
  .banner>h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  